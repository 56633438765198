import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["object", "input"]

    apply() {
        let form = this.objectTarget.closest("form")
        let variantsCount = form.querySelectorAll(".variants").length
        let object = this.objectTarget.value
        let inputs = this.inputTargets

        for (const input of inputs) {
            let attributeName = input.dataset["bulkEdit"]
            for (let i = 0; i < variantsCount; i++)
                if (input.value !== "")
                    form[`${object}[${i}][${attributeName}]`].value = input.value
        }
    }
}