
const MODE_LIGHT = "light"
const MODE_DARK = "dark"

// Whenever the user explicitly chooses light mode
// localStorage.theme = 'light'

// Whenever the user explicitly chooses dark mode
localStorage.theme = 'light'
// console.log(window.matchMedia('(prefers-color-scheme: dark)').matches)

// Whenever the user explicitly chooses to respect the OS preference
// localStorage.removeItem('theme')

// On page load or when changing themes, best to add inline in `head` to avoid FOUC
if (localStorage.theme === MODE_DARK || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
    document.querySelector('html').classList.add(MODE_DARK)
} else {
    document.querySelector('html').classList.remove(MODE_DARK)
}