import { Controller } from "stimulus"

require("choices.js/public/assets/styles/choices.min.css");
import Choices from "choices.js";

export default class extends Controller {
    connect() {
        new Choices(".smart-select:not(.choices__input)", {
            itemSelectText: '',
            removeItemButton: true,
            searchResultLimit: 20,
            duplicateItemsAllowed: false
        })
    }
}