import { Controller } from "stimulus"
import Choices from "choices.js";

export default class extends Controller {
    static targets =  ["imageName", "optionTypesInput"]

    // handle_images_name() {
    //     let color = this.colorTarget.options[this.colorTarget.options.selectedIndex].text
    //     color = color.replace(" ", "-").replace("/", "-")
    //
    //     let nameInputs = this.imageNameTargets
    //     for (let i = 0; i < nameInputs.length; i++) {
    //         nameInputs[i].value = nameInputs[i].dataset.nameTemplate.replace("{COLORNAME_HERE}", color)
    //     }
    // }

    connect() {
        this.optionTypesInputTarget.dispatchEvent(new Event("change"))
    }

    handleOptionTypeToVariant(event) {
        const optionTypeIds = Array.from(event.target.selectedOptions).map(option => option.value).filter(el => el.length > 0)
        let optionTypeHandler = new OptionTypeHandler(optionTypeIds)
        optionTypeHandler.execute()
    }
}

class OptionTypeHandler {
    constructor(optionTypeIds) {
        this.optionTypeIds = optionTypeIds
    }

    execute() {
        this._removeUnusedOptionValues()
        this._addNewOptionValues()
    }

    /**
     * For all used option type that are not in the one passed as argument, remove the input field.
     * @private
     */
    _removeUnusedOptionValues() {
        const existingOptionValues = this._getExistingOptionValues()
        for (const existingOptionValue of existingOptionValues) {
            // Delete condition happen here
            if (!this.optionTypeIds.includes(existingOptionValue)) {
                const toDelete = document.querySelectorAll(`.option_value_ids-${existingOptionValue}`);
                for (let i = 0; i < toDelete.length; i++)
                    toDelete[i].remove()
            }
        }
    }

    /**
     *
     * @private
     */
    _addNewOptionValues() {
        const existingOptionValues = this._getExistingOptionValues()
        for (const optionTypeId of this.optionTypeIds) {
            //Add condition happen here
            if (!existingOptionValues.includes(optionTypeId))
                this._cloneOptionValues(optionTypeId)
        }
    }

    /**
     *
     * @private
     */
    _getExistingOptionValues() {
        const variantOptionValuesRows = document.querySelectorAll(".variant-row .variant-option-values")
        if (variantOptionValuesRows.length)
            return Array.from(variantOptionValuesRows[0].querySelectorAll(".option_value_ids")).map(element => element.dataset.optionTypeId)
    }

    /**
     *
     * @private
     */
    _cloneOptionValues(optionTypeId) {
        const variantRows = document.querySelectorAll(".variant-row")
        for (const variantRow of variantRows) {
            let element = document.querySelector(`#template-option-type-${optionTypeId}`).content.cloneNode(true)
            let objectName = variantRow.querySelector(".row-object-name").value
            let selectElement = element.querySelector("select")
            selectElement.setAttribute("name", `${objectName}[option_value_ids][]`)
            variantRow.querySelector(".variant-option-values").append(element)
            new Choices(selectElement)
        }
    }



}