import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["sidebar", "overlay"]

    toggle() {
        this.sidebarTarget.classList.remove("hidden")
        this.sidebarTarget.classList.add("z-10")
        this.overlayTarget.classList.remove("hidden")
    }

    close() {
        this.sidebarTarget.classList.add("hidden")
        this.sidebarTarget.classList.remove("z-10")
        this.overlayTarget.classList.add("hidden")
    }

}